
import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "./SurgicalSpecialties";
import "./Minimally Invasive.scss";
import "./SurgicalSpecialties.scss";
import { videoPath } from "../../util";
import { VideoTemplate } from "../ui/VideoTemplate";

export const minimalInvasive = [
    {
        name: "Supine Position",
        src: "https://i.postimg.cc/28s67PcW/supine-position.jpg",
        alt: "Supine position",
        className: "supine",
        isImage: true,
        description: ` Supine position, also known as Dorsal Decubitus, is the most 
        frequently used position for procedures. In this reclining position, 
        the patient is face-up with their head resting on a pad
         or pillow and their neck  in a neutral position.
         The patient's arms should be tucked at the patient's sides with a bedsheet,
         or flexed and secured across the body or extended and secured on a padded 
         armboards.  In Supine position, the patient may risk pressure ulcers
          and nerve damage. This position causes extra pressure on the skin
           and bony prominences over the occiput, scapulae, elbows, sacrum,
            coccyx and heels`,
      },
    
      {
        name: "Supine video",
        videoUrl: videoPath("k69jdOnisohKs1AbqZa"),
        alt: "Supine-video",
        className: "supine-video",
      },
];


export function MinimalInvasiveSurgery() {
  return (
    <section
      className="specialty-types"
      onClick={(e) => clickHandler(e, "minimal")}
    >
      <span className="sub-headings">Minimally Invasive Surgeries</span>

      <div className="specialty minimal hide">
        {minimalInvasive.map((media) => (
          <div
            onClick={(e) => clickHandler(e, "minimal", `${media.className}`)}
          >
            <span>{media.name}</span>
            <div className={`${media.className} hide`}>
              {media.isImage ? (
                <ImgTemplate
                  src={media.src}
                  alt={media.alt}
                  description={media.description}
                  key={media.alt}
                />
              ) : (
                <VideoTemplate
                  className="operating-room-video"
                  title={media.name}
                  src={media.videoUrl}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}


