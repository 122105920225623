import "./Suturing.scss";
import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "../layouts/SurgicalSpecialties";
import { videoPath } from "../../util";
import { VideoTemplate } from "../ui/VideoTemplate";

/**
 * Toggles both a span and paragraph element.
 * @param {Event} event the event target object
 * @param {string} spanClassName the <span> CSS class
 * @param {string} paragraphClassName the <p> CSS class
 */
function readMore(event, spanClassName, paragraphClassName){
  const spanElement = document.querySelector(`.${spanClassName}`);

  if (spanElement.classList.contains("show")) {
    spanElement.classList.remove("show");
    spanElement.classList.add("hide");
  } else {
    spanElement.classList.add("show");
    spanElement.classList.remove("hide");
  }

  clickHandler(event, paragraphClassName)
}

export function Suturing() {
  const suture = {
    name: "Suture anatomy",
    src: "https://i.postimg.cc/SRXSWrcV/Suture.png",
    alt: "Suture anatomy",
  };

  const needles = {
    name: "Surgical needles",
    src: "https://i.postimg.cc/jjKSGyrd/surgical-sutures.webp",
    alt: "surgical needles",
  };

  return (
    <div className="search suturing">

      <h2>Suturing & Knot-Tying</h2>

      <div className="suture-anatomy">
        <a href={suture.src}>
          <ImgTemplate
            src={suture.src}
            alt={suture.alt}
            name={suture.name}
            key={suture.alt}
          />
        </a>
      </div>

      <div className="suture-materials">
        <h2 className="main-headings material-heading">Sutures Materials</h2>

        <div className="suture-material-description">
          <p>
            A variety of suture materials are available for ligating, suturing
            and closing the wound. The primary reason to use sutures is to
            encourage wound healing of the injured tissue to reduce the risk of
            infection.

            <span className= "see-more show"
            onClick={(e) => readMore(e, "see-more", "more")}>Read More</span>

          </p>

          <p className="more hide">
            Each types of suture has inherent characteristics, which affects its
            handling, tying and stability in tissue. Suture material is
            classified either as adsorbable or nonabsorbable. suture materials
            are also sub divided into braided and monofilament.
          </p>
        </div>

        <h2 className="main-headings types-heading">Types of Suture Materials</h2>

        <ul className="sub-headings material-lists">
          <li onClick={(e) => clickHandler(e, "absorbable-sutures")}>
            Absorbable suture
            <div className="absorbable-sutures hide">
              <hr />
              <p>
                Absorbable suture is defined as suture that loses most of its
                tensile strength with time. The time it takes for a suture to be
                degraded in tissue varies by type of material.
              </p>
              <p>
                Absorbable sutures can be degraded by enzyme activity or broken
                down by reaction with water in tissue fluid. They should not be
                utilized on body tissue that would require greater than two
                months of tensile strength. It is generally used internally
                during surgery.
              </p>
              <p>
                Types of absorbable suture include plain or chromic surgical gut
                and glycolic acid polymers. Examples are: Vicryl, PDS, Monocryl,
                collagen, gut, and chromic gut
              </p>
            </div>
          </li>

          <li onClick={(e) => clickHandler(e, "non-absorbable-sutures")}>
            Non-absorbable suture
            <div className="non-absorbable-sutures hide">
              <hr />
              <p>
                Non-absorbable suture are strands of material that effectively
                resist enzymatic digestion in living tissue. Non-absorbable
                suture material is encapsulated or walled of by the tissues
                around it during the process of wound healing.
              </p>
              <p>
                They hold greater tensile strength for longer periods of time.
                They are appropriate for tissues with a high degree of
                mechanical shear force such as; tendons. Nonabsorbable sutures
                include nylon, polyester ( Ethibond), polypropylene ( Prolene),
                silk, and steel. materials.
              </p>
            </div>
          </li>
          
        </ul>
      </div>

      <div className="surgical-needles">
        <h2 className="main-headings needle-heading">{needles.name}</h2>
        <ImgTemplate src={needles.src} alt={needles.alt} key={needles.alt} />

        <div className="needle-description">
          <p>
            surgical needles vary in shape, size, point and wire diameter.
            Needle selection is determined by the type of of tissue, suture
            material, and action to be performed. When selecting a suture size,
            the smaller the number, the larger the thread.
            <span className="needle-more show"
            onClick={(e) => readMore(e, "needle-more", "read-more")}>Read more</span>
          </p>
          <p className="read-more hide">
            A 1-0 is larger than a 6-0 suture. Needle shapes are designed to
            address specific procedural and anatomical demands. Straight needles
            are used when suturing easily accessible tissue with direct held
            manipulation. Curved needles, ranging from 1/8 circle to 5/8 circle
            can be used for everything from basic skin closure to cardiovascular
            and ophthalmic procedures.
          </p>
        </div>
      </div>

      <div className="needle-types">
        <h2 className="main-headings needle-heading">Needle Types</h2>

        <section className="sub-headings needle-list">
          <div onClick={(e) => clickHandler(e, "taper-point")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Taper point
            </span>
            <p className="point taper-point hide">
              This needle has a round shaft, straight, or curved, taper point.
              No cutting edge. It is commonly used for suturing soft tissues
              like gastrointestinal, fascial, vascular, and most soft tissues
              below the skin surface.
            </p>
          </div>

          <div onClick={(e) => clickHandler(e, "blunt-point")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Blunt point
            </span>
            <p className="point blunt-point hide">
              A blunt needlepoint is rounded at the tip, with no cutting edge,
              making it ideal for use in tissues that are easily damaged or
              require gentle handling. It is often used on fascia, liver,
              intestine, kidney, muscle and uterine cervix to reduce the risk of
              tissue trauma.
            </p>
          </div>

          <div onClick={(e) => clickHandler(e, "cutting")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Cutting
            </span>
            <p className="point cutting hide">
              Cutting: This needle has a cutting edge on the inside of the
              curve. Cutting needles are commonly used for cutaneous and
              subcutaneous closure.
            </p>
          </div>

          <div onClick={(e) => clickHandler(e, "reverse-cutting")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Reverse cutting
            </span>
            <p className="point reverse-cutting hide">
              This needle has a cutting edge on the outside of the curve, which
              helps it to penetrate tough tissue like skin or tendons.They are
              commonly used for skin closure, retention sutures, subcutaneous,
              and fibrous tissues.
            </p>
          </div>

          <div onClick={(e) => clickHandler(e, "taper-cut")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Taper Cut
            </span>
            <p className="point taper-cut hide">
              Taper cut needles are reverse-cutting tip with taper shaft. They
              are generally used on tougher tissues such as vasovasostomy,
              tuboplasty etc.
            </p>
          </div>

          <div onClick={(e) => clickHandler(e, "spatula")}>
            <span>
              <i class="fa-solid fa-hand-point-right"></i>
              Spatula
            </span>
            <p className="point spatula hide">
              spatula needles have two cutting edges in horizontal plane. They
              are commonly used for ophthalmic surgery for muscle, and retinal
              repair.
            </p>
          </div>

        </section>
      </div>

    </div>
  );
}

const sutureTechnique = [
  {
    name: "Simple Interrupted Suture",
    src: videoPath("k1A1VAi8ebuM8jAcbPs"),
    type: "video",
    className: "interrupted"
  },

  {
    name: "Buried/ Deep Dermal Suture",
    src: videoPath("k2LEFVE62VfMbEAcbPu"),
    type: "video",
    className: "deep-dermal"
  },

  {
    name: "Running Subcuticular Suture",
    src: videoPath("k2aW2QbN7k2VquAcbPA"),
    type: "video",
    className: "subcuticular"
  },

  {
    name: "Simple Running Suture",
    src: videoPath("k4LuZ6NtdXuGGaAcbPw"),
    type: "video",
    className: "simple-running"
  },

  {
    name: "Running Locking Suture",
    src: videoPath("k27hLpwwGgba4oAcbPI"),
    type: "video",
    className: "locking-running"
  },

  {
    name: "Vertical Mattress Suture",
    src: videoPath("k4vGbYgTCLLzbSAcbPE"),
    type: "video",
    className: "vertical-mattress"
  },

  {
    name: "Horizontal Mattress Suture",
    src: videoPath("k3A5UbIN2ArzosAcbPq"),
    type: "video",
    className: "horizontal-mattress"
  },
];

export function Technique() {
  return (
    <div className="search suture-techniques">
      <h2 className="main-headings technique-heading">Suture Techniques</h2>
      {sutureTechnique.map((media) => (
        <div
          onClick={(e) => clickHandler(e, `${media.className}`)}
          key={media.name}
        >
          <span className="sub-headings">{media.name}</span>

          <div className={`${media.className} hide`}>
            <VideoTemplate className="operating-room-video" src={media.src} />
          </div>
        </div>
      ))}
    </div>
  );
}

const knotTying = [
  {
    name: "Two Handed Knot Tie",
    videoUrl: videoPath("k3dy2rfacr1IZcAd3TC"),
    type: "video",
    className:"two-hand-tie"
  },

  {
    name: "One Handed Knot Tie",
    videoUrl: videoPath("k56vO173e7av5FAd3TA"),
    type: "video",
    className:"one-hand-tie"
  },

  {
    name: "Granny Knot Tie",
    videoUrl: videoPath("k5yXt4LbTt3mYlAd3TE"),
    type: "video",
    className:"granny-tie"
  },

  {
    name: "Surgeon's Knot Tie",
    videoUrl: videoPath("k40kZhxLonaqCQAd3Ty"),
    type: "video",
    className:"surgeon-tie"
  },
];

export function Tying() {
  return (

<div className="search knot-tying"> 
  <h2 className="main-headings knot-heading">Knot Tying Techniques</h2>
        {knotTying.map((media) => (
          <div onClick={(e) => clickHandler(e, `${media.className}`)} key={media.name}>
            <span className="sub-headings">{media.name}</span>
            <div className={`${media.className} hide`} >
            <VideoTemplate
                      className="operating-room-video"
                      title={media.name}
                      src={media.videoUrl}
                    />
              {/* <video className="operating-room-video" controls>
                <source src={media.src} type="video/mp4" />
              </video> */}
            </div>
          </div>
        ))}
      </div>
  );
}

