import "./Anesthesia.scss";
import { clickHandler } from "../layouts/SurgicalSpecialties.jsx";
import { VideoTemplate } from "../ui/VideoTemplate.jsx"
import { videoPath } from "../../util.js";

const anesthesia = [
    {
      name: "General anesthesia ",
      className:"general",
      src:videoPath("k1jwjbms0MciRNAd3Ow"),
      description: 
      `General anesthesia is administered through a mask or an IV placed in 
      the vein. Patient's are unconscious, and a tube is placed in
        their throat to help them breathe.Heart rate, blood pressure, breathing,
         and other vital signs will be monitor during surgery
         to make sure patient is normal and steady while they remain unconscious
          and free of pain.`
    },
    
    {
      name: "Regional anesthesia ",
      className:"regional",
      src:videoPath("k3fCkJsenQSWaZAd3Oy"),
      description: 
      `Regional anesthesia numbs a large part of the body. 
      The medication is delivered through an injection or small tube 
       called a catheter and is used when a simple injection of local anesthetic
        is not enough, and when it’s better for the patient to be awake.
        Two very common types of regional anesthesia are spinal and epidural 
        anesthesia. They are often used for childbirth or for orthopedic
         procedures such as total knee and total hip replacement.
          An epidural is the most common type of pain control used for
           labor and delivery. It allows the mother to be awake and able
            to push when it’s time to deliver the baby, but numbs the pain.`
    },
    
    {
      name: "Monitored anesthesia care",
      className:"monitored",
      src:videoPath("k4oTRB4nd9z4SdAd3OA"),
      description: `Monitored anesthesia care sedation and analgesics are 
      usually provided through an IV placed in a vein to make the patient
       feel drowsy and relaxed.
       Depending on the procedure, the level of sedation may range from minimal
        (to make the patient feel drowsy but able to talk) to deep 
        (patient not being able to talk or remembering the procedure).  `
    },
    
    {
      name: "Local anesthesia ",
      className:"local",
      src:process.env.PUBLIC_URL + "/video/local anesthesia.mp4",
      description: `Local anesthesia is an anesthetic agent given to 
      temporarily stop the sense of pain in a particular area of the body.
      It is often used along with sedation during minor outpatient surgery. 
      At the end of many operations, the surgeon may inject local anesthesia to
       provide additional pain relief during recovery. Local Anesthetics such as
       Lidocaine, mepivicaine, bupivicaine and ropivicaine are commonly used 
       for injections at surgical sites or for regional anesthesia (nerve blocks).
      `
    },
  ];
  
  export function AnesthesiaTypes() {
    return (
      <div id="anesthesia" className="search anesthesia"> 
        <h2 className="main-headings anesthesia-heading">Types of Anesthesia</h2>
      {anesthesia.map((media) => (
        <div onClick={(e) => clickHandler(e,"anesthesia", `${media.className}`)} 
        key={media.name}>
          <span className="sub-headings">{media.name}</span>
          <div className={`${media.className} anesthesia-content hide`} >
            <hr />
          <p className="anesthesia-p"> {media.description}</p>
          <VideoTemplate
          className="operating-room-video"
          title={media.name}
          src={media.src}
        />
          </div>
        </div>
      ))}
    </div>
    );
  }