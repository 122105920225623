
import "./Header.scss";
import { Nav } from "./Nav";
import { SearchBar } from "./SearchBar";

export function Header() {
  const imageUrl = "https://i.postimg.cc/tCxRJBq4/Surgical-Assistant.jpg";
  const imageName = "operating room staffs";

  return (
    <header>
      <SearchBar />
      <div className="nav-img">
        <Nav />

        <div className="page-img">
          <img src={imageUrl} alt={imageName} className="img" />
        </div>
        
      </div>
    </header>
  );
}
