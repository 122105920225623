import { videoPath } from "../../util.js";
import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "../layouts/SurgicalSpecialties";
import "./ThoracicSurgery.scss";
import { VideoTemplate } from "../ui/VideoTemplate.jsx";

export const thoracicImages = [
  {
    src: "https://i.postimg.cc/N0hHLyqW/chest-cavity.jpg",
    alt: "anatomy of the chest cavity",
    name: " The Chest Cavity",
    className: "chest-cavity",
  },

  {
    src: "https://i.postimg.cc/0jL8Zwfv/lungs.png",
    alt: "anatomy of the lung",
    name: " The Lungs",
    className: "lung",
  },
];

const lungVideo = {
  name: "How The Lung Works",
  videoUrl: videoPath("kKgKsuy3W4txkoAaVCq"),
  className: "lung-video",
};

export const thoracicVideos = [
  {
    name: "Airway Reconstruction",
    videoUrl: videoPath("k7yPoYYYfzaYI3Aaqyg"),
    className: "airway-reconstruction",
    description: `
      Airway reconstruction surgery is performed to widens the 
      trachea to make breathing easier. The procedure can be performed using
       a Non-Invasive (no incisions required / small puncture / low to moderate sedation)
        or a minimally-invasive (small incisions of 2–3 inches long / under general anesthesia).
        Children most commonly experience problems with a narrowed windpipe, 
        although the problem can also occur in adults. It can occur for 
        many reasons, including injury, infection, stomach acid reflux,
         a birth defect or as the result of the insertion of a breathing tube. 
       `,
  },

  {
    name: "Chest Wall Resection and Reconstruction",
    videoUrl: videoPath("k3chjD9GIQwVwsAaqyi"),
    className: "chest-wall",
    description: `A chest wall resection is performed to remove all or 
      part of soft tissue, cartilage,  sternum and/or ribs. 
      Chest wall resections are most commonly performed for tumors, infection,
       radiation necrosis, and trauma.
        A chest wall reconstruction uses artificial bone or titanium rib 
        plating  to help rebuild the skeletal structure of the chest wall 
        after chest wall resection. The decision to perform a reconstruction
         of the chest wall after resection will depend on the specific part
          affected, if there’s any cosmetic concern, or if there’s a
           possibility of respiratory failure.
       `,
  },

  {
    name: "Esophagectomy",
    videoUrl: videoPath("k2kgvlLUktCC8HAaqyw"),
    className: "esophagus",
    description: `
      Esophagectomy is the total removal or part of the esophagus, and
       nearby lymph nodes. Esophagectomy can be done open or with minimally
       invasive surgery, either by laparoscopy, robot assisted or a combination
       of these approaches.
       `,
  },

  {
    name: "Nissen fundoplication",
    videoUrl: videoPath("k3ICBCsifloCTwAaVpI"),
    className: "nissen",
    description: `
      A Nissen fundoplication is done to correct gastroesophageal reflux
      disease (GERD). The surgery tightens the junction between the esophagus
       and the stomach to prevent acid reflux. The procedure can be done open
        or with minimally invasive with a laparoscopy or robot.
       `,
  },

  {
    name: "lung transplant",
    videoUrl: videoPath("k19JkFRPr7zvO4AaVpA"),
    className: "lung-transplant",
    description: `
      A lung transplant is performed to remove a person’s diseased or failing
       lung and replace it with a healthy lung from a deceased donor.
       `,
  },

  {
    name: "Thoracic Outlet Decompression",
    videoUrl: videoPath("k2rkImLp34fBxrAaVpK"),
    className: "decompression",
    description: `
      Thoracic Outlet Syndrome (TOS) is a term used to describe a group of
       disorders that occur when there is compression, injury, or irritation
        of the nerves and/or blood vessels (arteries and veins) in the lower
         neck and upper chest area. To relief the pressure exerted on the
          nerves and/or blood vessels, a thoracic outlet decompression may
           be performed.
       `,
  },

  {
    name: "Decortication",
    videoUrl: videoPath("k41cER1PidsVeVAaqys"),
    className: "decortication",
    description: `
    Decortication is performed to remove a
     fibrous tissue that has abnormally formed on the surface of the lung,
     chest wall or diaphragm.The pleural space
      in between the lungs and the chest wall, is lined with a very thin
       fluid layer for lubrication. This area is moist to allow the lungs to
       expand and contract smoothly when breathing. However, certain diseases
        or conditions can lead to excess fluid that can fill up this gap.
        The excess fluid buildup is known as pleural effusion. If not fixed,
        the excess buildup of fluid can eventually turn solid and form a fibrous
        capsule that restricts and entraps the lung, causing breathing problems.
        Decortication can be performed open or minimally-Invasive.
       `,
  },

  {
    name: "Laparoscopic Heller Myotomy",
    videoUrl: videoPath("k2SL7u2ln3TSvsAaVpC"),
    className: "myotomy",
    description: `
    A Heller myotomy is a minimally invasive procedure
     used to treat achalasia. Achalasia is a disorder of the esophagus
     that makes it hard for foods and liquids to pass into the stomach.
      The lower esophageal sphincter (LES), a valve located at the end of
       the esophagus, stays closed during swallowing, resulting in the back up
        of food. There is no cure for achalasia, but it can be managed with
         treatment and medical therapy.
       `,
  },

  {
    name: "Lung Resection",
    videoUrl: videoPath("k5ZiUdz9N6cP9rAaVpy"),
    className: "lung-resection",
    description: `
    Lung resection is a surgical procedure performed to remove all or part
    of the lung. A lung resection is typically performed to treat an infection
     or disease of the lungs such as cancer, emphysema, or bronchiectasis.
     Lung resection can be performed open or minimally-Invasive. There are three types of lung
     resection:
   Wedge Resection: A wedge resection involves the removal of
   lung cancer along with a wedge-shaped section of tissue surrounding the
   tumor.
   Lobectomy: The most common type of lung resection. In this procedure,
   one or multiple lobes are removed from your lungs.

     Pneumonectomy: In this procedure, the entire lung is removed because of
     cancer, trauma, or some other condition.

       `,
  },

  {
    name: "Nuss Procedure",
    videoUrl: videoPath("k5Wjtro347hDruAaVpE"),
    className: "nuss",
    description: `
    Nuss procedure is a minimally-invasive procedure performed to correct
    severe pectus excavatum. Pectus excavatum is a condition in which the
    sternum is caved in. This happens because several ribs and the sternum
    grow abnormally. Most patients with pectus excavatum do not have symptoms.
     But for some with severe cases, chest pain or shortness of breath may
     occur.
    `
  },

  {
    name: "Robotic Dorsal Sympathectomy",
    videoUrl: videoPath("kaUYUprlm0iwJGAaVpM"),
    className: "sympathectomy",
    description: `Sympathectomy is performed to treat a condition known as
     hyperhidrosis; a condition that causes an abnormal excessive sweating or
    perspiration. It is a minimally-invasive procedure that is done
    by cauterizing (cutting and sealing) a portion of the sympathetic nerve
    chain that runs down the back inside the chest, parallel to the spine.
    The procedure permanently interrupts the nerve signal that is causing
    the body to sweat excessively.`
  },

   {
    name: "Robotic Thymectomy",
    videoUrl: videoPath("k1lptsJGABiBSzAaVpG"),
    className: "thymectomy",
    description:
     `A thymectomy is the surgical removal of the thymus gland.
    The thymus gland is in the front part of the chest just behind the breast
     bone and in front of the heart. The thymus is part of the body’s immune
      system, and plays its largest role early in a person’s development.
      Surgical removal of the thymus has no effect on the immune system for
       someone after they are born. Most people having a thymectomy have
       myasthenia gravis or a tumor. Thymectomy can be performed by several
       different surgical techniques: Transsternal Thymectomy, Transcervical Thymectomy
      and Robotic Thymectomy and Video-Assisted Thoracoscopic Thymectomy (VATS)
    `
  }
];

export function ThoracicSurgery() {
  const ListItem = thoracicVideos.map((media) => (
    <li className="procedures">
      <h4
        className="procedure-heading"
        onClick={(e) =>
          clickHandler(e, "thoracic-procedure-lists", `${media.className}`)
        }
      >
        {media.name}
      </h4>

      <div className={`${media.className} procedure-video hide`}>
        <p>{media.description}</p>
        
        <VideoTemplate
          className="operating-room-video"
          title={media.name}
          src={media.videoUrl}
        />
      </div>
    </li>
  ));

  return (
    <section
      className="specialty-types"
      onClick={(e) => clickHandler(e, "thoracic")}
    >
      <span className="sub-headings">Thoracic Surgeries</span>

      <div className="specialty thoracic hide">
        <div className="anatomy">
          {thoracicImages.map((media) => (
            <div
              onClick={(e) => clickHandler(e, "thoracic", `${media.className}`)}
              key={media.name}
            >
              <span>{media.name}</span>
              <div className={`${media.className} hide`}>
                <a href={media.src}>
                  <ImgTemplate
                    src={media.src}
                    alt={media.alt}
                    key={media.alt}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="procedures function-video">
          <h4
            className="procedure-heading"
            onClick={(e) =>
              clickHandler(e, "thoracic", `${lungVideo.className}`)
            }
          >
            {lungVideo.name}
          </h4>

          <div className={`${lungVideo.className} procedure-video hide`}>
            <VideoTemplate
              className="operating-room-video"
              title={lungVideo.name}
              src={lungVideo.videoUrl}
            />
          </div>
        </div>

        <div
          className="specialty-ul"
          onClick={(e) =>
            clickHandler(e, "thoracic", "thoracic-procedure-lists")
          }
        >
          <span>Surgical Procedures</span>
          <ul className="thoracic-procedure-lists hide"> {ListItem} </ul>
        </div>
      </div>
    </section>
  );
}
