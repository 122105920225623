

import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "./SurgicalSpecialties";
import "./NeuroSurgery.scss";
import "./SurgicalSpecialties.scss";
import { videoPath } from "../../util";
import { VideoTemplate } from "../ui/VideoTemplate";

export const neuroImages = [
  {
    src: "https://i.postimg.cc/pr8Z1FKt/Brain-function.jpg",
    alt: "anatomy of the brain",
    name: "Functions & Anatomy of the Brain",
    className: "brain",
  }, 
  
  {
    src: "https://i.postimg.cc/DfbBztHY/Brain-vascular.png",
    alt: "Blood Supply of the Brain",
    name: "Blood Supply of the Brain",
    className: "Supply",
  }, 
  
  {
    src: "https://i.postimg.cc/9MD1ZSM0/Cranial-Nerves.jpg",
    alt: "cranial nerves of the brain",
    name: "Cranial Nerves of the Brain",
    className: "nerves",
  },
  
  {
    src: "https://i.postimg.cc/8z4JTkbg/Spine-anatomy.png",
    alt: "spine anatomy",
    name: "Spine Anatomy",
    className: "spine",
  },
];

const neuroVideo = 
  {
  name: "Gross Anatomy of the brain",
  videoUrl: videoPath("k4hI0shvptEIBUAiyhE"),
  className: "brain-video"
  }

export const neuroVideos = [
 
  
  {
    name: "Craniotomy",
    videoUrl: videoPath("k37pGp7AvRt1wLAjKSw"),
    className: "craniotomy",
    description: `
    Craniotomy is a temporary removal of part of the bone from the skull 
    to expose the brain. The bone flap is later replaced at the end of the surgery.
    Craniotomy is done to treats tumors, blood clots and 
    epilepsy. It can take up to two months to heal after a craniotomy.
       `,
  },  


  {
    name: "Laser Ablation",
    videoUrl: videoPath("k6KvUR2wEvQD7PAjKSu"),
    className: "laser-ablation",
    description: `
    Laser ablation surgery is a treatment to remove tumors and other lesions. 
    It uses an MRI-guided laser probe to deliver light and heat to destroy
     unwanted cells. A very small hole (3.2 mm) is created in the skull, 
     through which a laser cathether is delivered to reach the brain tissue 
     that needs to be treated.The laser placement is done by using a stereotactic
      procedure, where scans of the brain are used to allow surgeons to pinpoint
       exactly the area of the brain that needs treatment. Once the catheter is 
       in place, the procedure continues under MRIguidance.
       `,
  },

  {
    name: "Ventriculoperitoneal (VP) shunt",
    videoUrl: videoPath("k5UFubKwi1oxAFAjKSs"),
    className: "ventricular-shunt",
    description: `
    A ventriculoperitoneal (VP) shunt is a cerebral shunt used to treat 
    hydrocephalus. The shunt removes excess cerebrospinal fluid. The procedure
     involves the placement of a VP shunt inside the brain ventricles to divert
      CSF away from the brain and into the peritoneal space of the abdomen or 
      other suitable drainage site in order to reduce pressure and swelling in
      the brain.
       `,
  },

  {
    name: "Pterional Craniotomy",
    videoUrl: videoPath("k7jFfu5uzEQsCsAjKMa"),
    className: "pterional-craniotomy",
    description: `
    The pterional craniotomy (also known as a frontotemporal, or frontotemporal
       sphenoidal craniotomy) is one of the most widely used neurosurgical 
       approaches. Pterional craniotomy is a unique approach that provides wide
        access to the skull base. The incision for this exposure should begin 
        approximately 1cm anterior to the tragus, starting at the zygoma. 
        This avoids damaging the superficial temporal artery, which runs 
        posteriorly, along with the frontalis branch of the facial nerve, 
        which lies anteriorly in a superficial fat pad. Pterional craniotomies
         are most frequently utilized for the surgical treatment of intracranial
          aneurysms. Majority of sporadic aneurysms are located at or near the Circle of Willis.
          Tumors in the suprasellar region are also often approached with pterional craniotomy.

       `,
  }, 

  {
    name: "Chiari Decompression",
    videoUrl: videoPath("k5X09aDZtHHvINAjKM8"),
    className: "chiari-decompression",
    description: `
    Chiari decompression is a surgical treatment for a rare condition called
     Arnold Chiari malformation, in which the brain tissue protrudes into the
      spinal canal at the back of the skull. The condition may be present at
       birth or may develop as the skull and brain grow. The procedure involves 
       the removal of a bone at the back of the skull to widen the foramen magnum 
       and create space for the brain. The dura mater may be opened. and, a 
       patch may be sewn in place to enlarge the covering and provide more room 
       for the brain. This patch may be an artificial material, or it could be
        tissue harvested from a different part of the body.
       `,
  },

  {
    name: " Anterior Cervical Discectomy",
    videoUrl: videoPath("ktyvPqZbNKoAABAjKLY"),
    className: "cervical-discectomy",
    description: `
    Anterior cervical discectomy is is performed to correct herniated discs in 
    the neck. The goal of the procedure is to remove the herniated disc and fusing the surrounding discs together.  
       `,
  },
  
  {
    name: "Lumbar Puncture",
    videoUrl: videoPath("k2p6Ef32NT1fe1AjKM0"),
    className: "lumbar-puncture",
    description: `
    Lumbar punctures, also known as spinal taps, is a diagnostic procedure that 
    is performed to determine if the patient has signs of any conditions related 
    to the central nervous system. To perform a lumbar puncture, a needle is inserted into 
    the spinal canal to extract cerebrospinal fluid.
       `,
  },
  
  {
    name: "Lumbar Laminectomy",
    videoUrl: videoPath("k5aJedgj8AEpK4AjKM4"),
    className: "laminectomy",
    description: `
    Laminectomies procedures relieve severe back pain. It involves removing part
     or all of the vertebral bone (lamina) to help ease pressure on the spinal 
     cord or the nerve roots that may be caused by injury, herniated disk, 
     narrowing of the canal (spinal stenosis),or tumors.
       `,
  },  
  
  {
    name: "Minimally Invasive Microdiscectomy  ",
    videoUrl: videoPath("k2YxlMWe9srCYeAjKM6"),
    className: "microdiscectomy ",
    description: `
    Microdiscectomy is a minimally invasive spine surgery. Disc material under
     the nerve root is removed with the use of a microscope. It is typically dine 
     to relief pain and other symptoms that occur when a herniated disc in the
      spine presses on an adjacent nerve root. 
       `,
  }, 
  
  
  {
    name: "Posterior Spinal Fusion",
    videoUrl: videoPath("k7ilfBqv3wyMr2AjKM2"),
    className: "spinal-fusion",
    description: `
    There are many different types of spinal fusion surgery, depending on the 
    condition and the patient. All types are performed to limit pain and provide
     stability to the spine. The spinal disc(s) between two or more vertebrae is
      removed and the adjacent vertebrae are ‘fused’ by bone grafts and/or metal 
      devices secured by screws. 
       `,
  },
  

];

export function NeuroSurgery() {
  const ListItem = neuroVideos.map((media) => (
    <li className="procedures">
    <h4
      className="procedure-heading"
      onClick={(e) =>
        clickHandler(e, "neuro-procedure-lists", `${media.className}`)
      }
    >
      {media.name}
    </h4>

    <div className={`${media.className} procedure-video hide`}>
      <p>{media.description}</p>
      
      <VideoTemplate
        className="operating-room-video"
        title={media.name}
        src={media.videoUrl}
      />
    </div>
  </li>
  ));

  return (
    <section className="specialty-types" onClick={(e) => clickHandler(e, "neuro")}>
      
      <span className="sub-headings">Neuro & Spine Surgeries</span>

      <div className="specialty neuro hide">

        <div className="anatomy">
          {neuroImages.map((media) => (
            <div
              onClick={(e) => clickHandler(e, "neuro", `${media.className}`)}
              key={media.name}
            >
              <span>{media.name}</span>
              <div className={`${media.className} hide`}>
                <a href={media.src}>
                  <ImgTemplate
                    src={media.src}
                    alt={media.alt}
                    key={media.alt}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="procedures function-video">
          <h4
            className="procedure-heading"
            onClick={(e) =>
              clickHandler(
                e,
                "neuro",
                `${neuroVideo.className}`
              )
            }
          >
            {neuroVideo.name}
          </h4>

          <div className={`${neuroVideo.className} procedure-video hide`}>
            <VideoTemplate
              className="operating-room-video"
              title={neuroVideo.name}
              src={neuroVideo.videoUrl}
            />
          </div>
        </div>

        <div className="specialty-ul" 
        onClick={(e) => clickHandler(e, "neuro", "neuro-procedure-lists")}>
          <span>Surgical Procedures</span>
          <ul className="neuro-procedure-lists hide"> {ListItem} </ul>
        </div>

      </div>


    </section>
  );
}