
import './App.scss';
import { Header } from './components/layouts/Header';
import { Footer } from './components/layouts/Footer';
import { ContentContainer } from './components/layouts/ContentContainer';

function App() {
  return (
   <main className='main-container'>
    <div className='arrow-icon'>
      <a href='#top'><i class="nav-arrow fa-solid fa-arrow-up-long"></i></a>
    </div>
    <LogoName/>
    <Header/>
    <ContentContainer/>
    <Footer/>
   </main>
  );
}

function LogoName() {
  return (
    <a href='/' id='top' className="logo-name">
      <i className="fa-solid fa-mask-face logo"></i>
      <h1 className="heading-primary">Surgical Library</h1>
    </a>
  );
}
export default App;
