import React from 'react'
import "./Footer.scss"

export const Footer = () => {
  return (
    <div className='footer'>
      <p>&copy; 2024 Surgical Library. All Rights Reserved.
 </p>

    </div>
  )
}
