import React from 'react'
import "./Nav.scss"
import { useState } from 'react';

export const Nav = () => {
 // adding the states 
 const [isActive, setIsActive] = useState(false);
 //add the active class
 const toggleActiveClass = () => {
   setIsActive(!isActive);
 };
 //clean up function to remove the active class
 const removeActive = () => {
   setIsActive(false)
 }
  return (
    <nav  className="nav-bar">
      <aside className={`menu menu-content ${isActive ? "active" : ""}`} onClick={toggleActiveClass}>
        <span className="menu__item"></span>
      </aside>

      <ul className={`nav_list ${isActive ? "show" : "hide"}`}>
        <li onClick={removeActive}>
          <a href="#specialties" className="nav_link">
            Surgical Specialties
          </a>
        </li>
        
        <li onClick={removeActive}>
          <a href="#positioning" className="nav_link">
          Positioning
          </a>
        </li>
        
        <li onClick={removeActive}>
          <a href="#aseptic" className="nav_link">
          Aseptic Techniques
          </a>
        </li>
        
        <li onClick={removeActive}>
          <a href="#suturing" className="nav_link">
          Suturing & knot-tying techniques
          </a>
        </li>
        
        <li onClick={removeActive}>
          <a href="#anesthesia" className="nav_link">
          Types of Anesthesia
          </a>
        </li>
        
        <li onClick={removeActive}>
          <a href="#operating-intro" className="nav_link">
          Common OR Equipments
          </a>
        </li>
      </ul>
    </nav>
  );

 


}