import "./Positioning.scss";
import { positioningImages } from "../ui/ImgTemplate";
import { operatingTables } from "../ui/ImgTemplate";
import { positioningAccessories } from "../ui/ImgTemplate";
import { ImgTemplate } from "../ui/ImgTemplate";
import { VideoTemplate } from "../ui/VideoTemplate";
import { clickHandler } from "../layouts/SurgicalSpecialties";

export function Positioning() {
  const ListItem = operatingTables.map((media) => (
    <li className="bed-position-accessories-lists bed-li">
      <h4
        className="bed-heading"
        onClick={(e) =>
          clickHandler(e, "operating-table", `${media.className}`)
        }
      >
        {media.name}
      </h4>
      <div className={`${media.className} hide`}>
        <ImgTemplate
          src={media.src}
          alt={media.alt}
          description={media.description}
          key={media.alt}
        />
      </div>
    </li>
  ));

  return (
    <div className=" search positioning" id="positioning">
      <h2 className="position-heading main-headings">
        Operating Beds & Accessories
      </h2>

      <div className="positioning-content">
        <div
          className="operating-beds"
          onClick={(e) => clickHandler(e, "operating-table")}
        >
          <span className=" sub-headings position-sub-heading">
            <i className="fa-solid fa-arrow-right arrow-1"></i>
            Operating Beds
          </span>
          <ul className="beds-accessories operating-table hide">{ListItem}</ul>
        </div>

        <div
          className="positioning-types"
          onClick={(e) => clickHandler(e, "positions")}
        >
          <span className=" sub-headings position-sub-heading">
            <i className="fa-solid fa-arrow-right arrow-2"></i>
            Positions
          </span>

          <div className="positions beds-accessories hide">
            {positioningImages.map((media) => (
              <div className="bed-position-accessories-lists position-lists">
                <h4
                  className="bed-heading"
                  onClick={(e) =>
                    clickHandler(e, "positions", `${media.className}`)
                  }
                >
                  {media.name}
                </h4>
                <div className={`${media.className} hide`}>
                  {media.isImage ? (
                    <ImgTemplate
                      src={media.src}
                      alt={media.alt}
                      description={media.description}
                      key={media.alt}
                    />
                  ) : (
                    <VideoTemplate
                      className="operating-room-video"
                      title={media.name}
                      src={media.videoUrl}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="positioning-accessories"
          onClick={(e) => clickHandler(e, "accessories")}
        >
          <span className=" sub-headings position-sub-heading">
            <i className="fa-solid fa-arrow-right arrow-3"></i>
            Positioning Accessories
          </span>

          <div className="accessories beds-accessories hide">
            {positioningAccessories.map((media) => (
              <div className="bed-position-accessories-lists accessories-lists">
                <h4
                  className="bed-heading"
                  onClick={(e) =>
                    clickHandler(e, "accessories", `${media.className}`)
                  }
                >
                  {media.name}
                </h4>

                <div className={`${media.className} hide`}>
                  {media.isImage ? (
                    <ImgTemplate
                      src={media.src}
                      alt={media.alt}
                      description={media.description}
                      key={media.alt}
                    />
                  ) : (
                    <VideoTemplate
                      className="operating-room-video"
                      title={media.name}
                      src={media.videoUrl}
                    />
                  )}
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
