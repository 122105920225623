import { videoPath } from "../../util.js";
import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "../layouts/SurgicalSpecialties";
import "./Vascular.scss";
import { VideoTemplate } from "../ui/VideoTemplate.jsx";

export const vascularImages = [
  {
    src: "https://i.postimg.cc/Dwzb0dWn/vascular-image.jpg",
    alt: "anatomy of the chest cavity",
    name: " The Vascular System",
    className: "vascular-system",
  },

];

const vascularVideo = {
  name: "How The Vascular system Works",
  videoUrl: videoPath("k3wR3GsXRl71KnAdPNw"),
  className: "vascular-video",
};

export const vascularVideos = [
  {
    name: "Atherectomy",
    videoUrl: videoPath("k544e3DHrnXlZOAilYi"),
    className: "atherectomy ",
    description: `
    Atherectomy is a minimally invasive procedure use to remove plaque
     buildup and open narrow or blocked arteries.
     The procedure helps restore healthy blood flow and relieves 
     symptoms of peripheral artery disease (PAD)
       `,
  },
  
  {
    name: "Arteriovenous (AV) Fistula",
    videoUrl: videoPath("k3UZmBGoZfjuz6AilYg"),
    className: "arteriovenous-fistula",
    description: `
    An arteriovenous fistula is when an artery and vein connect directly,
     allowing blood to flow incorrectly. These can be surgically created for
      people who need dialysis care. They can also happen because of injuries 
      or form in the womb before a person is born. While they’re sometimes
       dangerous, they’re usually treatable and often curable.
       `,
  },
  
  {
    name: "Arteriovenous Graft",
    videoUrl: videoPath("k5NKtrlGDQiAOPAilYa"),
    className: "arteriovenous-graft",
    description: `
    Similar to AV fistula procedure that creates an access point for dialysis,
     an AV graft procedure also involves surgically connecting an artery to a
      vein – but in this case, through a synthetic graft.
       `,
  },
  
  {
    name: "Carotid angioplasty and stenting:",
    videoUrl: videoPath("k3zftfC9dDDDX9AilYk"),
    className: "carotid-angioplasty",
    description: `
    Carotid angioplasty and stenting are procedures that open clogged arteries
     to restore blood flow to the brain. 
     During the procedure, a balloon catheter is guided to the area of the
      blockage or narrowing. When the balloon is inflated, the fatty plaque 
      or blockage is compressed against the artery walls to improve blood flow.
      A stent is also placed inside the carotid artery at the site of the 
      blockage to provide support to keep the artery open.

       `,
  },

  
  {
    name: "Open Aneurysm surgery ",
    videoUrl: videoPath("k4lWALB9yk2WoZAilYe"),
    className: "open-aneurysm",
    description: `
    Aneurysm surgery is a treatment for aortic aneurysms. 
   During the procedure the damaged part of the aorta is removed and 
   replaces with a synthetic graft.
       `,
  },
  
  
  {
    name: "Endovascular Aneurysm Repair (EVAR) ",
    videoUrl: videoPath("k3Z1CqRUlLljfNAim5q"),
    className: "evar",
    description: `
    Endovascular aneurysm repair (EVAR) is a minimally invasive 
    procedure that can be used to manage abdominal aortic aneurysms.
    It involves inserting a stent graft within the aneurysm through small 
    groin incisions using X-rays to guide the graft into place.
       `,
  }, 
  
  {
    name: "Carotid Endarterectomy",
    videoUrl: videoPath("k40wU5rkSlikahAim5y"),
    className: "carotid-endarterectomy",
    description: `
    A carotid endarterectomy is the surgical removal of plaque from the carotid 
    artery, which supplies blood to the brain and face. 
       `,
  },
  
  {
    name: "Peripheral artery bypass",
    videoUrl: videoPath("k7kOqS7uCG6WkfAim5u"),
    className: "peripheral-bypass",
    description: `
    A peripheral bypass, is performed to reroute blood flow around a blocked
     artery  in the leg, by creating a new pathway for blood flow using a synthetic graft.
       `,
  },

  {
    name: "Thrombectomy",
    videoUrl: videoPath("k1MH1ecEpdlVWqAim5w"),
    className: "thrombectomy",
    description: `
    A thrombectomy is performed to remove a blood clot from a vein or artery. 
    It is done to restore proper blood flow and prevent life-threatening 
    complications such as pulmonary embolism or cerebral embolism, 
    which can cause a stroke. Angioplasty and stenting may also be performed 
    at the same time as a thrombectomy.
       `,
  },
  
  {
    name: "Vena Cava Filter Placement",
    videoUrl: videoPath("k1xIlJmHcGdl5yAim5s"),
    className: "vena-cava",
    description: `
    Vena cava filters (IVC filters) help to prevent pulmonary embolism. 
    Providers may suggest an IVC filter if patient have blood clots and
     can’t take blood thinners. Filters may be permanent or retrievable, 
     and can be placed through an incision in the neck or groin.
       `,
  },

];

export function VascularSurgery() {
  const ListItem = vascularVideos.map((media) => (
    <li className="procedures">
      <h4
        className="procedure-heading"
        onClick={(e) =>
          clickHandler(e, "vascular-procedure-lists", `${media.className}`)
        }
      >
        {media.name}
      </h4>

      <div className={`${media.className} procedure-video hide`}>
        <p>{media.description}</p>
        
        <VideoTemplate
          className="operating-room-video"
          title={media.name}
          src={media.videoUrl}
        />
      </div>
    </li>
  ));

  return (
    <section
      className="specialty-types"
      onClick={(e) => clickHandler(e, "vascular")}
    >
      <span className="sub-headings">Vascular Surgeries</span>

      <div className="specialty vascular hide">
        <div className="anatomy">
          {vascularImages.map((media) => (
            <div
              onClick={(e) => clickHandler(e, "vascular", `${media.className}`)}
              key={media.name}
            >
              <span>{media.name}</span>
              <div className={`${media.className} hide`}>
                <a href={media.src}>
                  <ImgTemplate
                    src={media.src}
                    alt={media.alt}
                    key={media.alt}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="procedures function-video">
          <h4
            className="procedure-heading"
            onClick={(e) =>
              clickHandler(e, "vascular", `${vascularVideo.className}`)
            }
          >
            {vascularVideo.name}
          </h4>

          <div className={`${vascularVideo.className} procedure-video hide`}>
            <VideoTemplate
              className="operating-room-video"
              title={vascularVideo.name}
              src={vascularVideo.videoUrl}
            />
          </div>
        </div>

        <div
          className="specialty-ul"
          onClick={(e) =>
            clickHandler(e, "vascular", "vascular-procedure-lists")
          }
        >
          <span>Surgical Procedures</span>
          <ul className="vascular-procedure-lists hide"> {ListItem} </ul>
        </div>
      </div>
    </section>
  );
}
