import React from "react";
import "./ImgTemplate.scss";
import { videoPath } from "../../util";

export const introImages = [
  {
    src: "https://i.postimg.cc/RVy3b3FN/cautery.png",
    alt: "cautery machine",
    name: "Cautery machine",
    className: "cautery-machine",
    description: `Cautery machine uses an electrical current to apply heat to tissue in order to
    Cut tissue without searing it, Control bleeding from small blood vessels, and
    remove diseased tissue.`,
  },

  {
    src: "https://i.postimg.cc/htQmSLwL/back-table.jpg",
    alt: "back table",
    name: "Back table",
    className: "back-table",
    description: `The back table is a critical piece of equipment in any operating room.
      It holds most of the surgical instruments and other equipment 
      which will be used during surgery. `,
  },

  {
    src: "https://i.postimg.cc/Kcs4RKtJ/mayo-stand.jpg",
    alt: "Mayo stand",
    name: "Mayo Stand",
    className: "mayo-stand",
    description: `Mayo stands are used to hold surgical instruments and materials during 
     procedures. The height of a Mayo stand can be conveniently adjusted, and 
     it provides a convenient location which can be sterilized
      and positioned close to surgical sites without getting in the way.`,
  },

  {
    src: "https://i.postimg.cc/bJyd2k6F/kick-bucket.jpg",
    alt: "Kick bucket",
    name: "Kick Bucket",
    className: "kick-bucket",
    description: ` Kick bucket is used to maintain a sanitary and hygienic environment
     during surger. It is mostly used to collect sponges and other waste from surgery.`,
  },

  {
    src: "https://i.postimg.cc/BQXLg5Jr/IV-pole-stand.jpg",
    alt: "IV Pole",
    name: "IV Pole",
    className: "iv-pole",
    description: `An IV pole provides a secure place to hang bags of medicine or 
     fluid for administration to a patient.`,
  },

  {
    src: "https://i.postimg.cc/yNP8BWVh/suction-canister.jpg",
    alt: "Suction Canister",
    name: "Suction Canister",
    className: "suction-canister",
    description: ` Suction Canisters collects fluids,, gases and 
    bodily fluids during surgery.`,
  },

  {
    src: "https://i.postimg.cc/pTCh0qhH/barehugger.png",
    alt: "Bare Hugger",
    name: "Bare Hugger",
    className: "bare-hugger",
    description: ` Bare Hugger is used to keep patients warm before, during, and after surgery. `,
  },

  {
    src: "https://i.postimg.cc/HxksLHxq/sitting-stool.jpg",
    alt: "Seating Stool",
    name: "Seating Stool",
    className: "Seating-stool",
    description: `Surgical Seating stool help provide safe, comfortable and secure 
    seating in the operating room.`,
  },

  {
    src: "https://i.postimg.cc/3J4NpKB7/stand-step.jpg",
    alt: "Step Stool",
    name: "Step Stool",
    className: "step-stools",
    description: `Step Stools are designed to help provide safe elevation in the OR`,
  },
];

export const operatingTables = [
  {
    name: "General Surgical Bed",
    src: "https://i.postimg.cc/HnLJFXX7/general-surgical-table.png",
    alt: "General Surgical Bed",
    className: "general",
    description: `
    General surgical Bed are used in a wide variety of surgical 
    procedures like cardiovascular, pediatric, gynecology, gall bladder,
     and plastic surgery. It does not have one specialty, it is designed 
     for versatility and adaptability across a wide array of operations.
      It can can be adjusted for height and length, can be tilted to
       either side, and tilted horizontally.
    `,
  },

  {
    name: "Orthopedic fracture bed",
    src: "https://i.postimg.cc/bJmr0rWY/hana-fractures-bed.png",
    alt: "Orthopedic fracture bed",
    className: "fracture",
    description: `
     The Orthopedic fracture bed are designed to allow surgeons
      to perform common orthopedic procedures such as hip, pelvic 
      fractures, and more. Fracture bed allows staff to
         position and maneuver the table with ease and confidence.
          Surgeons get precise patient control and flexibility to
           perform a variety of open and minimally invasive orthopedic
            procedures. 
    `,
  },

  {
    name: "Jackson Bed",
    src: "https://i.postimg.cc/YSwvMJQ7/jackson-bed.webp",
    alt: "Jackson Bed",
    className: "jackson",
    description: `
     The Jackson Bed are designed for spine, imaging and orthopedic 
     trauma procedures. The Jackson bed allows the ability to 
     perform a 360-degree rotation without removing the patient 
     from the bed, combining the anterior and posterior
      positioning during a single operating.
    `,
  },
];

export const positioningImages = [
  
  {
    name: "Supine Position",
    src: "https://i.postimg.cc/28s67PcW/supine-position.jpg",
    alt: "Supine position",
    className: "supine",
    isImage: true,
    description: ` Supine position, also known as Dorsal Decubitus, is the most 
    frequently used position for procedures. In this reclining position, 
    the patient is face-up with their head resting on a pad
     or pillow and their neck  in a neutral position.
     The patient's arms should be tucked at the patient's sides with a bedsheet,
     or flexed and secured across the body or extended and secured on a padded 
     armboards.  In Supine position, the patient may risk pressure ulcers
      and nerve damage. This position causes extra pressure on the skin
       and bony prominences over the occiput, scapulae, elbows, sacrum,
        coccyx and heels`,
  },

  {
    name: "Supine video",
    videoUrl: videoPath("k69jdOnisohKs1AbqZa"),
    alt: "Supine-video",
    className: "supine-video",
  },
  



  {
    name: "Fowler's Position",
    src: "https://i.postimg.cc/HLQ7MpTj/fowlers-position.png",
    alt: "Fowler's Position",
    className: "fowler",
    isImage: true,
    description: `Fowler's position, also known as sitting/beach position, is typically
     used for neurosurgery and shoulder surgeries. When positioning a 
     patient in Fowler's position, the surgical staff should minimize
      the degree of the patient's head elevation as much as possible and
       always maintain the head in a neutral position. The patient's arms
        should be flexed and secured across the body, the buttocks should 
        be padded, and the knees flexed 30 degrees. In Fowler's position,
         the patient is at an increased risk for air embolism, skin injury
          from shearing and sliding, and DVT forming in the patient's 
          lower extremities. In this type of position, a patient has 
          an increased pressure risk in their scapulae, sacrum, coccyx,
           ischium, back of knees, and heels `,
  },

  {
    name: "Fowler Video",
    videoUrl: videoPath("k3uXpD91LSmyPlAbqZ6"),
    alt: "Fowler-video",
    className: "Fowler-video",
  },




  {
    name: "Prone position",
    src: "https://i.postimg.cc/pLKdGq46/prone-position.png",
    alt: "Prone position",
    className: "prone",
    isImage: true,
    description: ` In the prone position, the patient is positioned face-down with
     their head in a neutral position without excessive flexion, extension, 
     or rotation. The patient’s arms should be abducted less than 90 degrees 
     with the elbows flexed and palms down to maintain neutral alignment of
      the arms and wrists. Use foam or gel chest rolls supports 
      that extend from the clavicle to the iliac crest. Ensure the breast,
       abdomen, and genitals are free from pressure. Pad the patient’s
        knees. Elevate the patient’s toes off the bed with padding under
         the shins Use a face positioner when the patient’s head is in
        midline Prevent direct pressure on the patient’s eyes.`,
  },

  {
    name: "Prone Video",
    videoUrl: videoPath("k5zcJwC6RbJbIiAbqZc"),
    alt: "Prone-video",
    className: "prone-video",
  },





  {
    name: "Lithotomy position",
    src: "https://i.postimg.cc/Zn0RwZz8/lithotomy-icon.png",
    alt: "Lithotomy position",
    className: "lithotomy",
    isImage: true,
    description: `In Lithotomy position, the patient can be placed in either a 
    boot-style leg holder or stirrup-style position. Modifications to 
    this type of position include low, standard, high, exaggerated or
     hemi. The risks posed to a patient in a Lithotomy position for 
     a procedure include fractures, nerve injuries, hip dislocation,
      muscle injuries, pressure injuries, and diminished lung capacity.
       While positioning a patient in this position, surgical staff 
       should avoid hyperabduction of the patient's hips and leaning 
       against their inner thighs. Stirrups used on a patient in this
        position should disperse support and pressure over wide areas. `,
  },

  {
    name: "Lithotomy Video",
    videoUrl:videoPath("k2VAbslCsTg6TrAbqZ8"),
    alt: "Lithotomy-video",
    className: "lithotomy-video",
  },





  {
    name: "lateral position",
    src: "https://i.postimg.cc/hGbfjp0d/lateral.png",
    alt: "lateral position",
    className: "lateral",
    isImage: true,
    description: ` In Lateral position, the patient may be placed on either their left
     or right side depending on the side of the surgical site. Place a 
     pillow or foam under the patient’s head. Support and secure the 
     patient’s arms on parallel armboards with one arm on each armboard 
     and both arms abducted less than 90 degrees. Place an axillary roll
      under the patient’s dependent thorax. Flex the patient’s 
      dependent leg at the hip and knee. Position the patient’s upper leg
       straight and support it with pillows between the legs. Place safety
        straps across the patient’s hips. Use lateral braces, 
        kidney braces and bean bag positioners as needed.  `,
  },

  {
    name: "Lateral Video",
    videoUrl: videoPath("k7wUVWywlrnLu6AbqZ4"),
    alt: "lateral-video",
    className: "lateral-video",
  }


];

export const positioningAccessories = [
  {
    name: "Wilson frames",
    src: "https://i.postimg.cc/zB2DbMHV/wilson-frame.jpg",
    alt: "Wilson frames",
    className: "wilson",
    isImage: true,
    description: `Wilson frames are mounted to general surgical tables to provide
    a convenient and stable method of keeping patients in a flexed 
    position for laminectomy, decompression, disc surgery and
     microdiscectomy procedures. Wilson frames can also be 
     mounted to the Jackson table.`,
  },

  {
    name: "Mayfield Clamp",
    src: "https://i.postimg.cc/C5tKttwW/mayfield.jpg",
    alt: "Mayfield Clamp",
    className: "mayfield",
    isImage: true,
    description: `The Mayfield Clamp is a 3-pin head immobilization device designed
     to provide multifunctional options for cranial stabilization
      and fixation.`,
  },

  {
    name: "Mayfield video",
    videoUrl: videoPath("k3rbUBJbiZck2lAd3OC"),
    alt: "mayfield-video",
    className: "mayfield-video",
  },

  {
    name: "Yellofin stirrups",
    src: "https://i.postimg.cc/h4wPKkqY/Yellofins-Stirrups.jpg",
    alt: "Yellofin stirrups",
    className: "yellofin",
    isImage: true,
    description: `The Yellofins Stirrup is recognized as the "gold standard" 
    in lithotomy positioning. It enhanced surgical site access such as 
    perineal and rectal area. It is also used to support legs,
    protect the head of the fibula, and the peroneal nerve.
    It is mostly used in GYN, Urology, and Colorectal procedures `,
  },

  {
    name: "Candy Cane Stirrup",
    src: "https://i.postimg.cc/J42JV6T7/Candy-Cane-Stirrups.jpg",
    alt: "Candy Cane Stirrup",
    className: "candy-cane",
    isImage: true,
    description: `Just like Yellofins Stirrup, Candy Cane Stirrup are Ideal for
     lithotomy positioning. It provides protection from pressure insults 
     or shear and sufficient operating space.
     It is widely used for vaginal surgery `,
  },

  {
    name: "Positioning Gel Pads",
    src: "https://i.postimg.cc/x8FkQ83p/gel-pads.webp",
    alt: "Positioning Gel Pads",
    className: "gel-pads",
    isImage: true,
    description: `Positioning Gel Pads aims to reduce risks to patients and provides
     support to anatomical regions during surgery. They help reduce tissue pressure, avoid bed sores 
     and nerve damage. They are radiolucent. MRI and X-Ray compatible.
     They can be use for all types of positioning.`,
  },

  {
    name: "Positioning Foam Pads",
    src: "https://i.postimg.cc/Sx9X0y2V/Foam-Positioners.png",
    alt: "Positioning Foam Pads",
    className: "foam-pads",
    isImage: true,
    description: `Like the Gel Pads, the foam pads also aims to reduce risks to patients and provides
     support to anatomical regions during surgery. They help reduce tissue pressure, avoid bed sores 
     and nerve damage. They are disposable which guarantee prevention
      of cross contamination . They can be use for all types of positioning.`,
  },

  {
    name: "Bean Bag",
    src: "https://i.postimg.cc/qR46X97w/bean-bag.jpg",
    alt: "Bean Bag",
    className: "bean-bag",
    isImage: true,
    description: `Bean bags are regularly used to stabilize a patient’s position 
    on the operating bed. They support chest and trunk in lateral position
     and prevents sliding in steep trendelenburg.`,
  },
];

export function ImgTemplate(props) {
  return (
    <div>
      <h4>{props.name}</h4>

      <figure className="page-img">
        <img src={props.src} alt={props.alt} className="img" />
        <figcaption>{props.description}</figcaption>
      </figure>
    </div>
  );
}
