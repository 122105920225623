import "./SearchBar.scss";
import { clickHandler } from "../layouts/SurgicalSpecialties";




function searchInput(e){
  
  let searches = document.querySelectorAll(".search");
  let currentValue = e.target.value;

  searches.forEach((search) => {
    const spanEl = search?.firstElementChild
    console.log(spanEl);
    if (!spanEl.textContent.toLowerCase().includes(currentValue)) {
      search.style.display = "none";
      console.log(`not found ${spanEl}`)
    } 
    
  });
};


export function SearchBar() {
  return (
    <div className="search_box">
      <i className="search-icon fa-solid fa-magnifying-glass"
      onClick={(e) => clickHandler(e, "search-input")}></i>
      <input onChange={searchInput} className="search-input hide"
      type="search" name="search" id="search" placeholder="search" />
    </div>
  );
}
