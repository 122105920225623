import { ImgTemplate } from "../ui/ImgTemplate";
import { clickHandler } from "../layouts/SurgicalSpecialties";
import "./CardiacSurgery.scss";
import "./SurgicalSpecialties.scss";
import { videoPath } from "../../util";
import { VideoTemplate } from "../ui/VideoTemplate";

export const cardiacImages = [
  {
    src: "https://i.postimg.cc/mZd1CpW3/heart-2.jpg",
    alt: "anatomy of the heart",
    name: "The Heart",
    className: "heart",
  },
];

const cardiacVideo = 
  {
  name: "How The Heart Works",
  videoUrl: videoPath("k68j8KnITSBC93AaVCo"),
  className: "heart-video"
  }

export const cardiacVideos = [
  {
    name: "Coronary Angioplasty",
    videoUrl: videoPath("k76YO8nP3i7qS5Aaqyq"),
    className: "angioplasty",
    description: `Angioplasty is a treatment for narrowed or blocked arteries and veins
       in patients with coronary artery disease (heart attack, angina), 
       peripheral artery disease, and carotid artery disease.
       When angioplasty is performed in the blood vessels of the
        heart it is called coronary angioplasty or percutaneous coronary
         intervention (PCI). When done in the arteries that supply the arms
          and legs it is called peripheral angioplasty, and carotid
           angioplasty when done in the arteries that supply the head 
           and neck.
       `,
  },

  {
    name: "Ablation ",
    videoUrl: videoPath("k1v2V7sqwmqWtuAaqym"),
    className: "ablation",
    description: `Ablation also known as catheter ablation, is a technique used to
       treat abnormalities of the heart's electrical system that has caused 
       the heart to beat fast, irregularly, or with too many extra beats. 
       It involves ablating (destroying) a very small, targeted area of the 
       heart muscle.
        `,
  },

  {
    name: "Thoracic Aortic Aneurysm Repair",
    videoUrl: videoPath("k1Yjz6LuE88WvDAaVPI"),
    className: "aneurysm-repair",
    description: `Aortic aneurysm repair is a surgery to fix a weak, bulging, or torn
       section of the aorta. The aorta is the largest artery in the body. 
       It leaves your heart and travels through the chest and then down 
       through the abdomen (belly) before splitting into smaller arteries 
       that go to the legs. When an aneurysm happens in the section of the 
       aorta that goes from your heart to your abdomen, it is called a 
       thoracic aortic aneurysm, or TAA. If it happens in the section of
        the aorta that travels through the abdomen, it’s called an abdominal
         aortic aneurysm, or AAA.
        `,
  },

  {
    name: "Open Aortic valve replacement",
    videoUrl: videoPath("k4sLYNIO2nlUPjAaqyk"),
    className: "valve-replacement",
    description: `Aortic valve replacement is a treatment for aortic valve stenosis.
      Aortic stenosis; is a type of heart valve disease (valvular heart disease).
       This happens when the valve between the lower left heart chamber and the body's main artery
        (aorta) is narrowed and doesn't open fully. This reduces or blocks
         blood flow from the heart to the aorta and to the rest of the body.
         Patients may require heart surgery to repair or replace the aortic 
         valve. A less invasive option for a aortic valve repair is
         Transcatheter Aortic Valve Replacement (TAVR) 
        `,
  },

  {
    name: "Transcatheter aortic valve replacement (TAVR)",
    videoUrl: videoPath("k6ym7KRaVPQWcTAaVPG"),
    className: "transcatheter",
    description: `Transcatheter aortic valve replacement (TAVR) is valve replacement 
      without open-heart surgery. During TAVR, the replacement valve is 
      inserted through a small cut in the thigh into an artery using a catheter
       to navigate the valve to the heart.
        `,
  },

  {
    name: "Coronary Artery Bypass Grafting (CABG)",
    videoUrl: videoPath("k6TZUvEzPXGOm7AaVPM"),
    className: "bypass",
    description: `Coronary Artery Bypass Grafting (CABG) is a surgical treatment for 
      coronary artery disease. Coronary artery disease is a condition caused
       by the buildup of plaque the coronary arteries.
       During CABG surgery a blood vessel from the leg, chest, or arm is 
       used to create a new route (a bypass) around a blocked section of
        coronary artery. 
        `,
  },
  {
    name: "Heart Transplant",
    videoUrl: videoPath("k1Ic167tEgOcw0AaVPK"),
    className: "transplant",
    description: `Heart transplant surgery, also called cardiac transplantation,
       replaces a severely diseased or malformed heart with a new heart
        from a human organ donor. Heart transplant is considered for patients 
        with advanced heart failure after other treatments are no longer 
        helpful. Heart transplantation is a successful treatment for 
        advanced heart failure that offers an average of 9 to 15 years of 
        additional, high-quality life.
        `,
  },
];

export function CardiacSurgery() {
  const ListItem = cardiacVideos.map((media) => (
    <li className="procedures">
    <h4
      className="procedure-heading"
      onClick={(e) =>
        clickHandler(e, "cardiac-procedure-lists", `${media.className}`)
      }
    >
      {media.name}
    </h4>

    <div className={`${media.className} procedure-video hide`}>
      <p>{media.description}</p>
      
      <VideoTemplate
        className="operating-room-video"
        title={media.name}
        src={media.videoUrl}
      />
    </div>
  </li>
  ));

  return (
    <section className="specialty-types" onClick={(e) => clickHandler(e, "cardiac")}>
      <span className="sub-headings">Cardiac Surgeries</span>

      <div className="specialty cardiac hide">

        <div className="anatomy">
          {cardiacImages.map((media) => (
            <div
              onClick={(e) => clickHandler(e, "cardiac", `${media.className}`)}
              key={media.name}
            >
              <span>{media.name}</span>
              <div className={`${media.className} hide`}>
                <a href={media.src}>
                  <ImgTemplate
                    src={media.src}
                    alt={media.alt}
                    key={media.alt}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="procedures function-video">
          <h4
            className="procedure-heading"
            onClick={(e) =>
              clickHandler(
                e,
                "cardiac",
                `${cardiacVideo.className}`
              )
            }
          >
            {cardiacVideo.name}
          </h4>

          <div className={`${cardiacVideo.className} procedure-video hide`}>
            <VideoTemplate
              className="operating-room-video"
              title={cardiacVideo.name}
              src={cardiacVideo.videoUrl}
            />
          </div>
        </div>

        <div className="specialty-ul" 
        onClick={(e) => clickHandler(e, "cardiac", "cardiac-procedure-lists")}>
          <span>Surgical Procedures</span>
          <ul className="cardiac-procedure-lists hide"> {ListItem} </ul>
        </div>

      </div>


    </section>
  );
}
