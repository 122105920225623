import React from 'react'

export const VideoTemplate = (props) => {
  const hosted = props.src.includes("dailymotion");

  if (hosted) {
    return (
    <iframe
      width="100%"
      title={props.title}
      src={props.src}
      loading="lazy"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    ></iframe>
    )
  }

  return (
    <video className={props.className} controls key={props.title}>
      <source src={props.src} type="video/mp4" />
    </video>
  );
}
