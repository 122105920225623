import "./SurgicalSpecialties.scss";
import { CardiacSurgery } from "./CardiacSurgery";
import { ThoracicSurgery } from "./ThoracicSurgery";
import { VascularSurgery } from "./Vascular";
import { NeuroSurgery } from "./NeuroSurgery";
import { MinimalInvasiveSurgery } from "./Minimally Invasive";

/**
 * Toggles both an element's visibility on click.
 * @param {Event} event the DOM EventTarget object
 * @param {string} parentClass the parent element's CSS class
 * @param {string} childClass the optional child element's CSS class
 */
export function clickHandler(event, parentClass, childClass) {
  //0. The goal is to distinguish clicks between parent and child.
  //1. If 2nd parameter ('childClass') exists, then we've clicked on a child.
  //2. If 2nd param doesn't exist, then we've clicked on a parent.

  //If parent click, only need 1 querySelector, don't change logic
  //If child click, ignore parent. Create 2nd querySelector for child.

  let parentEl = document.querySelector(`.${parentClass}`);
  // console.log(parentEl);

  if (childClass) {
    parentEl = parentEl.querySelector(`.${childClass}`);
    // console.log(parentEl);
    event.stopPropagation(); //only trigger event on the child, and ignore parent.
  }

  if (parentEl.classList.contains("hide")) {
    parentEl.classList.remove("hide");
    parentEl.classList.add("show");
  } else {
    parentEl.classList.add("hide");
    parentEl.classList.remove("show");
  }
}

export function SurgicalSpecialties() {
  return (
   
      <div id="specialties" className="search specialties">
    <h2 className="main-headings">Surgical Specialties</h2>
    <ul className="specialty-list">
      <li><CardiacSurgery /></li>
      <li><ThoracicSurgery /></li>
      <li><VascularSurgery /></li>
      <li><NeuroSurgery /></li>
      <li>< MinimalInvasiveSurgery /></li>
    </ul>
    </div>

  );
}


