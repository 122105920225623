import React from 'react'
import "./ContentContainer.scss"
import "./Suturing.scss"
import {OperatingRoomIntro}  from "./OperatingRoom101"
import { Positioning } from './Positioning';
import { AsepticTechniques } from './AsepticTechniques';
import { Suturing } from './Suturing';
import { Technique } from './Suturing';
import { Tying } from './Suturing';
import { AnesthesiaTypes } from './Anesthesia';
import { SurgicalSpecialties } from "./SurgicalSpecialties";


export function ContentContainer() {

  return (
    <section className="content-container">
      <SurgicalSpecialties/>
      <Positioning/>
      <AsepticTechniques/>
      <div id='suturing' className='suture-knot'>
      <Suturing/>
      <Technique/>
      <Tying/>
      </div>
      <AnesthesiaTypes/>
      <OperatingRoomIntro/>
    </section>
  );
}



