import "./AsepticTechniques.scss"
import { clickHandler } from "../layouts/SurgicalSpecialties";
import { videoPath } from "../../util";
import { VideoTemplate } from "../ui/VideoTemplate";

const asepticVideos = [
  { name: "Surgical Hand Scrub",
    videoUrl: videoPath("k32SGp0w9WypZyAbqFy"),
    className: "hand-scrub"
  }, 
  
  { name: "Self gowning and gloving",
    videoUrl: videoPath("k4Dv5i7htaRsmqAbqFu"),
    className: "gown-glove"
  },
  
  { name: "Opening Sterile items",
    videoUrl: videoPath("k19d8dLzQGyW3qAbqFs"),
    className: "sterile-items"
  },
  
  { name: "Catheter insertion ",
    videoUrl: videoPath("k1NYlSGBITBZpJAbqFA"),
    className: "catheter-insertion"
  },
  
  { name: "Chlora Prep",
    videoUrl: videoPath("k5lWW3l9RAKpOZAaqyo"),
    className: "chlora"
  },
  
  { name: "Dura Prep",
    videoUrl: videoPath("k6uMH2zqPxTm1cAaqyu"),
    className: "dura"
  },
  { name: "Betadine Prep",
    videoUrl: videoPath("k5yQFKgVaJkPeBAbqFw"),
    className: "betadine"
  },
]

export function AsepticTechniques() {
  return (
    <div id="aseptic" className="search aseptic">
      <h2 className="main-headings aseptic-heading">Aseptic Techniques</h2>
      {asepticVideos.map((media) => (
        <div
          className="aseptic-lists"
          onClick={(e) => clickHandler(e, `${media.className}`)}
          key={media.name}
        >
          <span className="sub-headings">{media.name}</span>

          <div className={`${media.className} hide`}>
            <VideoTemplate
              className="operating-room-video"
              title={media.name}
              src={media.videoUrl}
            />
          </div>
        </div>
      ))}
    </div>
  );
}