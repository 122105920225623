import "./OperatingRoom101.scss"
import {introImages} from "../ui/ImgTemplate"
import {ImgTemplate} from "../ui/ImgTemplate"
import { clickHandler } from "../layouts/SurgicalSpecialties";

export function OperatingRoomIntro() {
  return (
    <div className=" search operating-room-101" id="operating-intro">
      <h2 className="main-headings operating-heading">Common OR Equipments</h2>
      <div>
        {introImages.map((media) => (
          <div
            onClick={(e) => clickHandler(e,`${media.className}`)}
            key={media.name}
          >
            <span className="sub-headings">{media.name}</span>
            <div className={`${media.className} operating-items hide`}>
              <p className="operating-p"> {media.description}</p>
              <ImgTemplate src={media.src} alt={media.alt} key={media.alt} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}